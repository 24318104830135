import React from 'react';
import ReactDOM from 'react-dom/client';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n.js';


const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FF6B40',
    },
    info: {
      main: '#979EAB',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Poppins',
      fontSize: '72px',
    },
    h2: {
      fontFamily: 'Poppins',
      fontSize: '64px',
      fontWeight: '700',
    },
    h3: {
      fontSize: '24px',
      fontWeight: '600',
    },
    subtitle1: {
      fontSize: '18px',
      fontWeight: '700',
    },
    subtitle2: {
      fontSize: '16px',
      fontWeight: '400',
    },
    body1: {
      fontSize: '16px',
      fontWeight: '400',
    },
    body2: {
      fontSize: '12px',
      fontWeight: '500',
    },
    fontFamily: [
      'Open Sans',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),

  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
