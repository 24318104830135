import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'linear-gradient(120.85deg, #11112E 13.86%, #232755 74.76%);',
    padding: '15px 120px',
  },
  marginText: {
    marginRight: '50px',
  },
  marginIcon: {
    marginRight: '35px',
  },

}));
