import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';

import Logo from '../../assets/images/Logo.svg';
// @ts-ignore
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram-icon.svg';
// @ts-ignore
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook-icon.svg';
// @ts-ignore
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter-icon.svg';
import { useStyles } from './styles';

const Footer = () => {
  const classes = useStyles();

  return (
    <footer>
      <Box className={classes.footerContainer}>
        <img src={Logo} alt="logo" />
        <Box sx={{ display: 'flex' }}>
          <Typography
            className={classes.marginText}
            color="common.white"
            variant="subtitle2"
          >
            Contributors
          </Typography>
          <Typography
            className={classes.marginText}
            color="common.white"
            variant="subtitle2"
          >
            Help/Documentation
          </Typography>
          <Typography variant="subtitle2" color="common.white">Contact</Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <IconButton className={classes.marginIcon}>
            <InstagramIcon />
          </IconButton>
          <IconButton className={classes.marginIcon}>
            <FacebookIcon />
          </IconButton>
          <IconButton>
            <TwitterIcon />
          </IconButton>
        </Box>
      </Box>
    </footer>
  );
};
export default Footer;
