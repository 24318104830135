import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Home } from '../pages/Home';
import { Workspace } from '../pages/Workspace';

const Router: React.FC = () => {
  return (
    <Routes>
      <Route path="/login" element={<Workspace />} />
      <Route path="/" element={<Home />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
      <Route path="/404" element={<div>Page Not Found</div>} />
    </Routes>
  );
};

export default Router;
