import React from 'react';
import {
  AppBar, Box,Grid, Button, Toolbar, Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { HashLink as Link } from 'react-router-hash-link';

import Logo from '../../assets/images/Logo.svg';

import { StyledButton } from '../StyledButton';
import { useStyles } from './styles';
import {useTranslation} from "react-i18next";

const LandingHeader = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();


  const moveToTop = () => {
    if (location.pathname.includes('/login')) {
      navigate('/');
    } else {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        background: 'linear-gradient(120.85deg, #11112E 13.86%, #232755 74.76%);',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25);',
      }}
    >
      <Toolbar className={classes.toolbar}>
        <Button onClick={moveToTop}>
          <img
            src={Logo}
            alt="logo"
            style={{ width: '10vw' }}
          />
        </Button>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid container 
            spacing={2}
  direction="row"
  justifyContent="center"
  alignItems="center">

            <Grid item>
              <Link to="/#who-are-we" className={classes.disableLinkDecoration}>
                <Typography
                    className={classes.headerText}
                    color="white"
                >
                  {t('HEADER.LINKS.WHO_WE_ARE', { ns: 'homepage' })}
                </Typography>
              </Link>


            </Grid>
            <Grid item>
              <Link to="/#features" className={classes.disableLinkDecoration}>
                <Typography
                    className={classes.headerText}
                    color="white"
                >
                  {t('HEADER.LINKS.FEATURES', { ns: 'homepage' })}
                </Typography>
              </Link>
            </Grid>
            <Grid item>
              <Link to="/#book-a-demo" className={classes.disableLinkDecoration}>
                <Typography
                    sx={{ marginRight: '8vw' }}
                    color="white"
                >
                  {t('HEADER.LINKS.BOOK_A_DEMO', { ns: 'homepage' })}
                </Typography>
              </Link>
            </Grid>
            <Grid item>

            <StyledButton variant="contained" label="Sign in" action={() => navigate('/login')} />
            </Grid>
          </Grid>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default LandingHeader;
