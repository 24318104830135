/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import {
  Box, InputLabel, MenuItem, Select, TextareaAutosize, TextField, Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  JOB_FUNCTIONS_SELECT,
  LOCATIONS_SELECT,
  REFERRAL_SOURCES_SELECT,
  SENIORITY_LEVELS_SELECT, USER_PREFERENCES_SELECT,
} from '../../../src/constants/homepage';
import { useStyles } from './styles';
import { StyledButton } from '../StyledButton';
// @ts-ignore
import { ReactComponent as InactiveStepIcon } from '../../assets/icons/inactive-step-icon.svg';
// @ts-ignore
import { ReactComponent as ActiveStepIcon } from '../../assets/icons/active-step-icon.svg';

const DemoForm = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [secondStep, setSecondStep] = useState(false);

  const {
    handleSubmit, trigger, control, formState: { errors }, reset,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      jobTitle: '',
      company: '',
      location: '',
      jobFunction: '',
      seniority: '',
      hearAboutUs: '',
      whatDescribesYou: '',
      comment: '',
    },
  });

  const handleNextStep = async () => {
    const isValid = await trigger(['firstName', 'lastName', 'email', 'jobTitle']);
    if (isValid) {
      setSecondStep(true);
    }
  };

  const onSubmit = async (data: any) => {
    await fetch('https://dpm5beo01k.execute-api.eu-west-2.amazonaws.com/prod/send-email', {
      method: 'POST',
      body: JSON.stringify({
        data,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    }).then(() => {
      reset();
      setSecondStep(false);
    }).catch((err) => {
      console.error(err);
    });
  };

  return (
    <form style={{
      display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 3,
    }}
    >
      <div style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '48px',
      }}
      >
        <div
          className={classes.stepController}
          onClick={
            () => {
              setSecondStep(false);
            }
        }
        >
          {secondStep ? (
            <InactiveStepIcon />
          ) : (
            <ActiveStepIcon />
          )}

          Step 1
        </div>

        <div
          className={classes.stepController}
          onClick={
            () => {
              handleNextStep();
            }
        }
        >
          {secondStep ? (
            <ActiveStepIcon />
          ) : (
            <InactiveStepIcon />
          )}
          Step 2
        </div>

      </div>
      <div style={{ display: secondStep ? 'none' : 'block' }}>
        <Box className={classes.formRow}>
          <Box>
            <Typography>First name*</Typography>
            <InputLabel htmlFor="first-name" />

            <Controller
              name="firstName"
              control={control}
              rules={{
                required: 'First name required',
              }}
              render={({ field: { onChange, value } }) => (
                <>
                  <TextField
                    id="first-name"
                    onChange={onChange}
                    value={value}
                    type="text"
                    className={classes.textField}
                  />
                  <Typography sx={{ color: '#D40000' }}>{errors.firstName?.message}</Typography>

                </>
              )}
            />
          </Box>

          <Box>
            <Typography>Last name*</Typography>
            <InputLabel htmlFor="last-name" />

            <Controller
              name="lastName"
              control={control}
              rules={{ required: 'Last name required' }}
              render={({ field: { onChange, value } }) => (
                <>
                  <TextField
                    id="last-name"
                    onChange={onChange}
                    className={classes.textField}
                    value={value}
                    type="text"
                  />
                  <Typography sx={{ color: '#D40000' }}>{errors.lastName?.message}</Typography>
                </>
              )}
            />

          </Box>
        </Box>
        <Box className={classes.formRow}>

          <Box>
            <Typography>Email*</Typography>
            <InputLabel htmlFor="email-field" />

            <Controller
              name="email"
              control={control}
              rules={{
                required: 'Email required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              }}
              render={({ field: { onChange, value } }) => (
                <>
                  <TextField
                    onChange={onChange}
                    value={value}
                    id="email-field"
                    type="email"
                    className={classes.textField}
                  />
                  <Typography sx={{ color: '#D40000' }}>{errors.email?.message}</Typography>

                </>
              )}
            />
          </Box>

          <Box>

            <Typography>Job title*</Typography>
            <InputLabel htmlFor="job-title" />

            <Controller
              name="jobTitle"
              control={control}
              rules={{ required: 'Job title is required' }}
              render={({ field: { onChange, value } }) => (
                <>
                  <TextField
                    onChange={onChange}
                    className={classes.textField}
                    value={value}
                    id="job-title"
                  />
                  <Typography sx={{ color: '#D40000' }}>{errors.jobTitle?.message}</Typography>
                </>
              )}
            />
          </Box>
        </Box>
      </div>
      <div style={{ display: secondStep ? 'block' : 'none' }}>
        <Box className={classes.formRow}>

          <Box>
            <Typography>Company name*</Typography>
            <InputLabel htmlFor="company-name" />

            <Controller
              name="company"
              control={control}
              rules={{
                required: 'Company name is required',
              }}
              render={({ field: { onChange, value } }) => (
                <>
                  <TextField
                    id="company-name"
                    onChange={onChange}
                    value={value}
                    type="text"
                    className={classes.textField}
                  />
                  <Typography sx={{ color: '#D40000' }}>{errors.company?.message}</Typography>

                </>
              )}
            />
          </Box>

          <Box>
            <Typography>Where are you based?*</Typography>
            <InputLabel htmlFor="selected-location" />

            <Controller
              name="location"
              control={control}
              rules={{
                required: 'Location is required',
              }}
              render={({ field: { onChange, value } }) => (
                <>
                  <Select
                    id="selected-location"
                    value={value}
                    onChange={(e) => onChange(e)}
                    className={classes.selectField}
                  >
                    {LOCATIONS_SELECT?.map((location: string) => (
                      <MenuItem key={location} value={location}>
                        {location}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography sx={{ color: '#D40000' }}>{errors.location?.message}</Typography>
                </>
              )}
            />

          </Box>
        </Box>
        <Box className={classes.formRow}>

          <Box>
            <Typography>Job function*</Typography>
            <InputLabel htmlFor="selected-job-function" />

            <Controller
              name="jobFunction"
              control={control}
              rules={{
                required: 'Job function is required',
              }}
              render={({ field: { onChange, value } }) => (
                <>
                  <Select
                    id="selected-job-function"
                    value={value}
                    onChange={(e) => onChange(e)}
                    className={classes.selectField}
                  >
                    {JOB_FUNCTIONS_SELECT?.map((jobFunction: string) => (
                      <MenuItem key={jobFunction} value={jobFunction}>
                        {jobFunction}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography sx={{ color: '#D40000' }}>{errors.jobFunction?.message}</Typography>

                </>
              )}
            />

          </Box>

          <Box>
            <Typography>Seniority*</Typography>
            <InputLabel htmlFor="selected-seniority-level" />

            <Controller
              name="seniority"
              control={control}
              rules={{
                required: 'Seniority is required',
              }}
              render={({ field: { onChange, value } }) => (
                <>
                  <Select
                    id="selected-seniority-level"
                    value={value}
                    onChange={(e) => onChange(e)}
                    className={classes.selectField}
                  >
                    {SENIORITY_LEVELS_SELECT?.map((level: string) => (
                      <MenuItem key={level} value={level}>
                        {level}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography sx={{ color: '#D40000' }}>{errors.seniority?.message}</Typography>
                </>
              )}
            />

          </Box>
        </Box>
        <Box className={classes.formRow}>

          <Box>
            <Typography>How did you hear about us? </Typography>
            <InputLabel htmlFor="selected-hearAboutUs" />

            <Controller
              name="hearAboutUs"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  id="selected-hearAboutUs"
                  value={value}
                  onChange={(e) => onChange(e)}
                  className={classes.selectField}
                >
                  {REFERRAL_SOURCES_SELECT?.map((source: string) => (
                    <MenuItem key={source} value={source}>
                      {source}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />

          </Box>

          <Box>
            <Typography>What best describes you? </Typography>
            <InputLabel htmlFor="selected-whatDescribesYou" />

            <Controller
              name="whatDescribesYou"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  id="selected-whatDescribesYou"
                  value={value}
                  onChange={(e) => onChange(e)}
                  className={classes.selectField}
                >
                  {USER_PREFERENCES_SELECT?.map((level: string) => (
                    <MenuItem key={level} value={level}>
                      {level}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />

          </Box>
        </Box>
        <Box>
          <Typography>Comment</Typography>
          <InputLabel htmlFor="comment-textarea" />

          <Controller
            name="comment"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextareaAutosize
                id="comment-textarea"
                onChange={onChange}
                value={value}
                minRows={5}
                className={classes.textareaField}
              />
            )}
          />

        </Box>
      </div>

      {!secondStep ? (
        <StyledButton
          variant="contained"
          width="200px"
          label={t('BOOK_A_DEMO_SECTION.NEXT_BUTTON', { ns: 'homepage' })}
          margin=" 50px 0px "
          action={() => handleNextStep()}
        />
      ) : (
        <div className={classes.secondStepButtonsWrapper}>
          <StyledButton
            variant="outlined"
            width="200px"
            label={t('BOOK_A_DEMO_SECTION.PREVIOUS_BUTTON', { ns: 'homepage' })}
            margin=" 50px 0px "
            action={() => setSecondStep(false)}
          />
          <StyledButton
            variant="contained"
            width="200px"
            label={t('BOOK_A_DEMO_SECTION.SUBMIT_BUTTON', { ns: 'homepage' })}
            margin=" 50px 0px "
            action={handleSubmit(onSubmit)}
          />
        </div>
      )}

    </form>
  );
};

export default DemoForm;
